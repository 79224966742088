<script setup>

export default {
  props: {
    content: String,
    maxLength: Number,
  },
  data() {
    return {
      readMoreActivated: false
    }
  }
}
</script>

<template>
  <div>
    <span v-if="!readMoreActivated">{{ content.slice(0, maxLength) }}</span>
    <a class="" v-if="!readMoreActivated" @click="readMoreActivated = true">
      ... lire plus
    </a>
    <div v-if="readMoreActivated"><span v-html="content"></span>
      <a class="" @click="readMoreActivated = false">
        reduire
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
